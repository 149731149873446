import React from 'react';
import { graphql } from 'gatsby';
import ModuleZone from '~moduleZone';
import Layout from '@components/layout';
import { PageMeta } from '@components/pageMeta';
// import ContactForm from "@components/forms/contactForm";

const MarlboroughPage = ({ preview = false, data, previewDoc }) => {
  const page = preview ? previewDoc : data?.page;
  const { modules, seo } = page || {};

  return (
    <Layout>
      <PageMeta {...seo} />
      {modules && <ModuleZone {...modules} />}
    </Layout>
  );
};

export default MarlboroughPage;

export const pageQuery = graphql`
  query sanityMarlboroughPageQuery {
    page: sanityMarlboroughPage {
      seo {
        ...SEO
      }
      modules {
        ...AboutModules
      }
      contactUs {
        header
        body: _rawBody(resolveReferences: { maxDepth: 10 })
      }
    }
  }
`;
